import { api } from '../store.api'
import deserialize from 'util/deserialize'

export const meApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMyProfile: build.query({
      query() {
        return {
          method: 'GET',
          url: `me/profile`,
        }
      },
      providesTags: ['Me'],
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
    }),
    getMe: build.query({
      query() {
        return {
          method: 'GET',
          url: `me`,
          params: {
            included: ['profile'],
          },
        }
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: deserialize(response),
        }
      },
      providesTags: (response) => [{ type: 'Organization', id: response?.data.id }],
    }),
    getMyOrganizations: build.query({
      query() {
        return {
          method: 'GET',
          url: `me/organizations`,
        }
      },
      providesTags: ['Me'],
    }),
    updateMyProfile: build.mutation({
      query(attributes) {
        return {
          method: 'PATCH',
          url: `me/profile`,
          body: {
            data: { attributes },
          },
        }
      },
      invalidatesTags: ['Me'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetMyProfileQuery,
  useGetMyOrganizationsQuery,
  useGetMeQuery,
  useUpdateMyProfileMutation,
} = meApi