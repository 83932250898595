import { Component } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import notifyError from '../../../util/helpers/notifyError'

export default class ErrorBoundary extends Component {
  constructor(props = {}) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, _errorInfo) {
    notifyError(error)
  }

  get headerHeight() {
    return document.querySelector('#app-header')?.getBoundingClientRect().height || 0
  }

  render() {
    const { hasError, error } = this.state

    if (hasError) {
      notifyError(new Error('[DEBUG] ErrorBoundary fallback'))
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={`calc(100vh - ${this.headerHeight}px - 48px)`}
        >
          <Box
            component="section"
            bgcolor="background.paper"
            borderRadius="1px"
            maxWidth={540}
            width="calc(100% - 20px)"
          >
            <Box p={4.5}>
              <Typography variant="body1">
                An error has occurred, please refresh the page and try again.
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    }

    return this.props.children
  }
}
