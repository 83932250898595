import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from './store.utils'

const tagTypes = [
  'AccountManager',
  'AccountManagerAssignment',
  'Area',
  'Alert',
  'Applicant',
  'Area',
  'Authentication',
  'Availability',
  'Calendar',
  'Country',
  'Export',
  'Inclusion',
  'Interview',
  'Location',
  'Me',
  'Member',
  'Note',
  'Opening',
  'Organization',
  'Position',
  'Preoccupation',
  'Profile',
  'Questionnaire',
  'View',
  'Page',
  'Position',
  'Template',
  'Section',
  'Image',
  'Region',
  'Response',
  'Settings',
  'User',
  'Directions',
  'OrganizationFont',
  'Authentication',
]

export const api = createApi({
  reducerPath: 'api',
  tagTypes,
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({}),
})
