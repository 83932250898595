class StoreRegister {
  constructor() {
    this.myStore = null
  }

  set store(store) {
    this.myStore = store
  }

  get store() {
    return this.myStore
  }
}

export default new StoreRegister()