import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import store from 'store'
import { Box, CircularProgress } from '@mui/material'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import runtimeEnv from 'util/runtimeEnv'
import { ErrorBoundary } from './shared/components'

const Routes = React.lazy(() => import("./routes"));
const env = runtimeEnv();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense
    fallback={
      <Box
        display="flex"
        minHeight="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    }
  >
    <Helmet>
      <script type="text/javascript">
        {process.env.NODE_ENV === "production" && env.REACT_APP_HOTJAR_SCRIPT}
      </script>
    </Helmet>
    <ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    </ErrorBoundary>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
