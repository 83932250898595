import axios from 'util/axios'

export const axiosBaseQuery = () => 
  async ({ url, method, body = {}, ...rest }) => {
    try {
      const result = await axios({ 
        url, 
        method, 
        ...rest, 
        data: body, 
      })
      return { data: result?.data, meta: result?.data?.meta }
    } catch (axiosError) {
      let err = axiosError
      return {
        error: { status: err.response?.status, data: err.response?.data?.errors || [] },
      }
    }
  }

export const encodeNestedParams = (baseKey, param = {}) => {
  return Object.entries(param).reduce((aggregate, [key, value]) => {
      aggregate[`${baseKey}[${key}]`] = value
      return aggregate
    },
    {}
  )
}

export function providesList(resultsWithIds, tagType) {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }]
}

export function providesIncluded(resultsWithIds) {
  return resultsWithIds ? [...resultsWithIds.map(({ id, type }) => ({ type, id }))] : []
}

export function isHandledQueryError(error) {
  return error?.data !== undefined
}

export const defaultData = Object.assign([], { meta: {} })