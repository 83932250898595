import { configureStore } from '@reduxjs/toolkit'
import storeRegister from './storeRegister'
import rootReducer from './rootReducer'
import { api } from './store.api'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
})

storeRegister.store = store

export default store