import axios from 'axios'
import { validate } from 'uuid'
import runtimeEnv from './runtimeEnv'

const env = runtimeEnv()
const baseURL =
  env.REACT_APP_BASE_API_URL || process.env.REACT_APP_BASE_URL || 'http://localhost:3000'

const instance = axios.create({
  baseURL: `${baseURL}/v1`,
  headers: {
    'content-type': 'application/json',
  },
})

instance.interceptors.request.use((configuration) => {
  const access = JSON.parse(localStorage.getItem('access'))
  configuration.headers.Authorization = `Bearer ${access?.attributes?.token}`

  return configuration
})

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (process.env.NODE_ENV === 'production') {
      const url = err?.response?.config?.url
        .split('/')
        .map((path) => {
          if (validate(path)) {
            return ':id'
          }
          return path
        })
        .join('/')
      let error = ''
      try {
        error = JSON.stringify(err?.response?.data)
      } catch {}
    }
    return Promise.reject(err)
  }
)

export default instance