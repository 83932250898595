import { useGetMyProfileQuery } from 'store/me/me.api'
import { useMemo } from 'react'

const ProtectedRoute = ({ children }) => {
  const { isSuccess: isAuthenticated, isLoading: meLoading } = useGetMyProfileQuery()
  const organizationId = localStorage.getItem('organizationId')
  const shouldRedirect = useMemo(() => localStorage.getItem('shouldRedirect'), [])
  const path = encodeURIComponent(`/organizations/${organizationId}/landing-pages`)
  const queryParam = shouldRedirect && organizationId ? `?redirect=${path}` : ''

  if (!isAuthenticated && !meLoading) {
    window.location.assign(`${process.env.REACT_APP_KROW_CLIENT_URL}/login${queryParam}`)
    localStorage.removeItem('shouldRedirect')
  }

  return children
}

export default ProtectedRoute
