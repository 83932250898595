import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

const FullScreenLoading = ({children}) => (
  <Box
    data-cy="main-loader"
    display="flex"
    minHeight="100vh"
    alignItems="center"
    justifyContent="center"
  >
    <Stack 
      direction='column'
      alignItems='center'
      spacing={4}
    >
      <CircularProgress />
      {children}
    </Stack>
  </Box>
)

export default FullScreenLoading